<template>
<div class="index">
  <!-- 头部组件 -->
    <Header />
    <!-- 商标头部搜索分栏 -->
    <ColumnSearch :buldword="5"/>
    <div class="back">
      <h1 class="wbw">问标网</h1>
      <p class="word01">大数据商标查询平台</p>
      
    </div>
    <div class="tedian">
      <div class="content">
        <div class="box">
          <img src="../imger/About/data.png" alt="问标网" class="icon1">
          <div class="right">
            <p class="word01">超6000万+</p>
            <p>商标全量大数据</p>
          </div>
        </div>
        <div class="box">
          <img src="../imger/About/tu.png" alt="问标网" class="icon1">
          <div class="right">
            <p class="word01">近10年</p>
            <p>专业商标经验</p>
          </div>
        </div>
        <div class="box">
          <img src="../imger/About/int.png" alt="问标网" class="icon1">
          <div class="right">
            <p class="word01">8年+</p>
            <p>互联网开发</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 内容 -->
    <div class="title01">
      <div class="content">
        <div class="title">
        <strong class="wordtit">免费查商标就用问标网</strong>
        <div class="xiaoxi">Free trademark search Just use the wenbiao</div>
        </div>
        <p>问标网是问权知识产权旗下大数据商标查询平台，专注于提供免费的注册商标查询服务，问标网运营主体是中国国家商标局正规备案机构，总部位于中国广州。</p>
        <p>问标网始终践行“免费查商标，就用问标网”的服务理念，技术团队拥有8年以上互联网开发和技术推广经验，目前智能聚合超6000万以上的商标全量大数据，商标数据状态跟中国商标局官网信息保持及时同步，打开问标网小程序在线即可便捷的免费查询商标注册信息，最大程度帮助商标申请人降低商标注册风险，并及时监测商标注册进度和商标各种动向，为企业品牌保护提供全生命周期的一站式商标服务。</p>
        <p>问标网基于商标全量大数据，致力于采用人工智能应用技术研发推出“准确、多维、同步”的商标数字化管理智能AI技术工具。问标网聚焦“1+4”技术服务体系，主要提供：商标查询助手、商标在线注册、商标在线认领、商标智能检测、商标管理工具等智能数字技术服务，全力为中国产品向中国品牌转变以及高质量发展战略崛起献一份力量。</p>
      </div>
    </div>

    <div class="title02">
      <Title :word01="Titledata[0].word01" 
      :word02="Titledata[0].word02" 
      :word03="Titledata[0].word03"/>
      <div class="bigbox">
        <div class="box" v-for="(item,index) in boxData" :style="{backgroundColor:item.back}" :key="index">
          <img :src="item.tupian" :alt="item.title" class="icon">
          <p class="boxtitle">{{item.title}}</p>
          <p>{{item.wear}}</p>
        </div>
      </div>
    </div>

    <div class="title03">
      <Title :word01="Titledata[1].word01" 
      :word02="Titledata[1].word02" 
      :word03="Titledata[1].word03"/>
    </div>
  
  <!-- 点击弹出之后的弹出框 -->
    <Drawer />
    <!-- 固定弹窗按钮 -->
    <Eject />
    <!-- sidebar组件 -->
    <Sidebar />
    <!-- 页面关于我们脚部组件 -->
    <Footer />  
</div>
</template>

<script>
import Header from '../components/header.vue'
import Footer from '../components/footer.vue'
import Sidebar from '../components/sidebar.vue'
import Eject from '../components/eject.vue'
import Drawer from '../components/drawer.vue'
import ColumnSearch from '../components/columnSearch.vue'
import TradHeader from '../components/trademarkClassification/tradHeader.vue'

import Title from '../components/trademarkRegistration/title.vue'

import icon1 from '../imger/About/icon1.png'
import icon2 from '../imger/About/icon2.png'
import icon3 from '../imger/About/icon3.png'
import icon4 from '../imger/About/icon4.png'
import icon5 from '../imger/About/icon5.png'


export default {

  components:{
    Sidebar,Footer,Header,Drawer,Eject,ColumnSearch,
    TradHeader,Title
  },
  data(){
    return {
      Titledata:[
        {
          word01:"word",
          word02:"问标网",
          word03:"业务",
        },
        {
          word01:"process",
          word02:"商标注册",
          word03:"流程",
        },
      ],
      boxData:[
        {
          tupian:icon1,
          back:'#E5EEFF',
          title:'商标查询助手',
          wear:'问标网智能聚合超6000万全国商标注册大数据，信息与国家商标局官网保存及时同步，致力成为最准确智能的商标查询助手。'
        },
        {
          tupian:icon2,
          back:'#F8E8E7',
          title:'商标在线注册',
          wear:'问标网研发推出商标网上注册系统(2.0)，商标申请人通过问标网小程序即可在线自主申报，商标申请全流程操作简单、方便快捷。'
        },
        {
          tupian:icon3,
          back:'#E9FAFA',
          title:'商标在线认领',
          wear:'问标网提供商标认领服务，商标申请人或持有人在线即可实名认领名下注册商标，认领后在线自主提交商标变更、到期续展等申请。'
        },
        {
          tupian:icon4,
          back:'#EEEAFD',
          title:'商标智能监测',
          wear:'问标网上线“商标智能监测”功能，帮助用户智能监测所关注商标的申请进展状态及各项变化动向，大大降低商标被异议无效的风险。'
        },
        {
          tupian:icon5,
          back:'#FDF3EC',
          title:'商标管理工具',
          wear:'问标网基于商标大数据和人工智能技术，研发推出商标管理工具，帮助商标申请权利人实现商标申请、商标管理、商标维权的一站式服务。'
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.index {
  width: 100%;
  .back {
    width: 100%;
    height:33rem /* 528px -> 33rem */;
    background-image: url("../imger/About/bg.png");
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .wbw {
      display: inline;
      font-size: 2.25rem /* 36px -> 2.25rem */;
      color: #fff;
      padding-bottom: 0.625rem /* 10px -> 0.625rem */;
      box-sizing: border-box;
      border-bottom: 0.3125rem /* 5px -> 0.3125rem */ solid #fff;
      font-weight: 400;
    } 
    .word01 {
      font-size: 3rem /* 48px -> 3rem */;
      color: #fff;
      font-weight: bold;
      letter-spacing: 0.1875rem /* 3px -> 0.1875rem */;
      text-shadow: 0 0.5rem /* 8px -> 0.5rem */ 0 rgba($color: #000000, $alpha: 0.4);
    }
    
  } 
  .tedian {
    width: 100%;
    height: 7.4375rem /* 119px -> 7.4375rem */;
    background-color: #949DAE;
    display: flex;
    justify-content: center;
    .content {
      width: 74.375rem /* 1190px -> 74.375rem */;
      height: 100%;
      display: flex;
      justify-content: space-between;
      .box {
        width:14.375rem /* 230px -> 14.375rem */;
        display: flex;
        align-items: center;
        
        .icon1 {
          width: 3.0625rem /* 49px -> 3.0625rem */;
          height: 2.875rem /* 46px -> 2.875rem */;
        }
        .right {
          margin-left: 1.25rem /* 20px -> 1.25rem */;
          font-size: 1.125rem /* 18px -> 1.125rem */;
          color: #FFFFFF;
          .word01 {
            font-size: 1.875rem /* 30px -> 1.875rem */;
            color: #fff;
          }
        }
      }
    }
  }
  .title01 {
    height: 31.75rem /* 508px -> 31.75rem */;
    width: 100%;
    background-color: #F5F5F5;
    display: flex;
    justify-content: center;
    padding-top: 2.1875rem /* 35px -> 2.1875rem */;
    box-sizing: border-box;
    .content {
      width: 74.375rem /* 1190px -> 74.375rem */;
      height: 100%;
      font-size: 1.125rem /* 18px -> 1.125rem */;
      color: #555555;
      line-height: 2.375rem /* 38px -> 2.375rem */;
      text-indent: 2rem;
      .title {
        display: flex;
        justify-content: center;
        .wordtit {
          width: 15rem /* 240px -> 15rem */;
          font-size: 3rem /* 48px -> 3rem */;
          color: #4D89F1;
          line-height: 3.3125rem /* 53px -> 3.3125rem */;
          text-indent: 0rem;
        }
        .xiaoxi {
          height: 7.875rem /* 126px -> 7.875rem */;
          width: 25rem /* 400px -> 25rem */;
          background-image: url("../imger/About/xiaoxi.png");
          background-size: cover;
          background-repeat: no-repeat;
          font-size: 1.875rem /* 30px -> 1.875rem */;
          color: #fff;
          margin-left: 2.5rem /* 40px -> 2.5rem */;
          padding-top: 0.625rem /* 10px -> 0.625rem */;
          padding-left: 0.625rem /* 10px -> 0.625rem */;
          padding-right: 0.625rem /* 10px -> 0.625rem */;
          box-sizing: border-box;
          line-height: 2.1875rem /* 35px -> 2.1875rem */;
          text-indent: 0rem;
        }
      }
    }
  }
  .title02 {
    width: 100%;
    height: 42.375rem /* 678px -> 42.375rem */;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    .bigbox {
      width: 74.375rem /* 1190px -> 74.375rem */;
      height: 24.5rem /* 392px -> 24.5rem */;
      display: flex;
      justify-content: space-between;
      .box {
        width: 14.0625rem /* 225px -> 14.0625rem */;
        height: 100%;
        border-radius: 0.625rem /* 10px -> 0.625rem */;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        font-size: 1rem /* 16px -> 1rem */;
        color: #666666;
        line-height: 1.875rem /* 30px -> 1.875rem */;
        padding: 1.375rem /* 22px -> 1.375rem */;
        box-sizing: border-box;
        .icon{
          width: 5.3125rem /* 85px -> 5.3125rem */;
          height: 5.3125rem /* 85px -> 5.3125rem */;
        }
        .boxtitle {
          font-size: 1.375rem /* 22px -> 1.375rem */;
          color: #333;
        }

      }
    }
  }
  .title03 {
    width: 100%;
    height: 45.625rem /* 730px -> 45.625rem */;
    background-image: url("../imger/About/bottom.png");
    background-size: cover;
    padding-top: 2.5rem /* 40px -> 2.5rem */;
    box-sizing: border-box;
  }
}
</style>